
// import React, { useEffect, useState } from "react";
// import QRCode from "qrcode.react";
// import axios from "axios";
// import "./QRCodeGenerator.css";
// import { BaseUrl } from "../shared/BaseUrl";
// import { toast } from "react-toastify";
// import "./Content.css";
// import { Spin, Select } from "antd";
// import { Option } from "antd/es/mentions";

// const QRCodeGenerator = () => {
//   const [text, setText] = useState("");
//   const [label, setLabel] = useState("");
//   const [file, setFile] = useState(null);
//   const [category, setCategory] = useState(""); // State for category
//   const [qrCodeURL, setQRCodeURL] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [Type, setType] = useState('permanent');
//   const [duration, setDuration] = useState(10);
//   const [isSecure, setIsSecure] = useState(false);
//   const [pin, setPin] = useState('');

//   const token = localStorage.getItem("token");

//   useEffect(() => {
//     const fetchQr = async () => {
//       try {
//         const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         console.log({ response });
//         setQRCodeURL(response.data._id);
//       } catch (error) {
//         console.error("Login error:", error.message);
//       }
//     };
//     fetchQr();
//   }, [token]);

//   const handleGenerate = async () => {
//     setLoading(true); // Show spinner
//     try {
//       const formData = new FormData();
//       formData.append("file", file); // Append file to FormData

//       // JSON object with text and qrCodeURL
//       console.log("content duration is", duration);
//       console.log("content type is", Type);
      
      
//       const data = {
//       text: text,
//       qrCodeId: qrCodeURL, // Ensure the key matches what the backend expects (qrCodeId vs qrCodeURL)
//       label: label,
//       category: category,
//       isTemporary: Type === 'temporary' ? true : false,
//       contentDuration: Type === 'temporary' ? Number(duration) : null,
//       isSecure,
//       pin: isSecure ? pin : null,
//       };

//       console.log("data before sending", data);
      

//       const response = await axios.post(
//         `${BaseUrl}/api/content/content`,
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "multipart/form-data", // Set Content-Type for FormData
//           },
//           params: data, // Send text and qrCodeId as URL params or query params (optional)
//         }
//       );
      
//       toast.success(response.data.message, {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//       });
//       console.log({ response });
//     } catch (error) {
//       console.error("Error creating content", error);
//     } finally {
//       setLoading(false); // Hide spinner
//     }
//   };

//   return (
//     <div className="qr-code-generator-container">
//       <div className="generator-containers">
//         <h2 style={{ color: "rgb(243, 181, 98)" }}>Upload content</h2>
//         <br />
//         <input
//           style={{
//             borderRadius: "2rem",
//             borderBottom: "2px solid rgb(243, 181, 98)",
//             borderTop: "2px solid rgb(243, 181, 98)",
//           }}
//           className="qr-code-generator-input"
//           type="text"
//           placeholder="Enter text"
//           value={text}
//           onChange={(e) => setText(e.target.value)}
//           disabled={file !== null} // Disable text input if a file is selected
//         />
//         <h1 style={{ color: "rgb(243, 181, 98)" }}>OR</h1>
//         {/* <h3 style={{color:"rgb(243, 181, 98)"}}>Category</h3> */}
//                 <Select
//           showSearch
//           style={{ width: "100%", marginBottom: "1rem", borderRadius: "2rem" }}
//           placeholder="Select a category"
//           allowClear
        
//           optionFilterProp="children"
//           onChange={(value) => setCategory(value)} // Update state on change
//           value={category} // Bind to category state
//           defaultValue={"Select a category"}
//           filterOption={(input, option) =>
//             option.children.toLowerCase().includes(input.toLowerCase())
//           }
//         >
//           <Option value="Personal">Personal</Option>
//           <Option value="Family">Family</Option>
//           <Option value="Party">Party</Option>
//           <Option value="Work">Work</Option>
//           <Option value="Other">Other</Option>
//         </Select>
//         {/* <h3 style={{color:"rgb(243, 181, 98)"}}>Label</h3> */}
//         <input
//           style={{
//             borderRadius: "2rem",
//             borderBottom: "2px solid rgb(243, 181, 98)",
//             borderTop: "2px solid rgb(243, 181, 98)",
//           }}
//           className="qr-code-generator-input"
//           type="text"
//           placeholder="Enter Label"
//           value={label}
//           onChange={(e) => setLabel(e.target.value)}
//           // Disable text input if a file is selected
//         />

        

//         <label
//           htmlFor="file-upload"
//           className="custom-file-upload-content"
//           disabled={text !== ""}
//         >
//           Choose File
//           <input
//             id="file-upload"
//             type="file"
//             onChange={(e) => setFile(e.target.files[0])}
//             disabled={text !== ""}
//             style={{ display: "none" }}
//           />
//         </label>

//         <label className="custom-file-upload-content">
//         <input
//           type="radio"
//           value="permanent"
//           checked={Type === 'permanent'}
//           onChange={() => setType('permanent')}
//         />
//         Permanent
//       </label>

//       <label className="custom-file-upload-content">
//         <input
//           type="radio"
//           value="temporary"
//           checked={Type === 'temporary'}
//           onChange={() => setType('temporary')}
//         />
//         Temporary
//       </label>

//       {Type === 'temporary' && (
//         <div>
//           <label className="custom-file-upload-content">Duration (minutes):</label>
//           <input
//             type="number"
//             min="10"
//             max="1440"
//             value={duration}
//             onChange={(e) => setDuration(e.target.value)}
//           />
//         </div>
//       )}

//       <label className="custom-file-upload-content">
//         <input
//           type="checkbox"
//           checked={isSecure}
//           onChange={() => setIsSecure(!isSecure)}
//         />
//         Secure Content
//       </label>

//       {isSecure && (
//         <div>
//           <label className="custom-file-upload-content">Enter PIN:</label>
//           <input
//             type="password"
//             value={pin}
//             onChange={(e) => setPin(e.target.value)}
//           />
//         </div>
//       )}
//         {file && <span className="file-name">{file.name}</span>}
//         <button className="qr-code-generator-button" onClick={handleGenerate} style={{marginBottom:"3rem"}}>
//           {loading ? (
//             <Spin
//               tip="Uploading..."
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 height: "auto",
//               }}
//             />
//           ) : (
//             "Upload"
//           )}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default QRCodeGenerator;


// import React, { useEffect, useState } from "react";
// import QRCode from "qrcode.react";
// import axios from "axios";
// import { BaseUrl } from "../shared/BaseUrl";
// import { toast } from "react-toastify";
// import { Spin, Select, Card, Input, Radio, Checkbox, Button } from "antd";
// import "./Content.css";

// const { Option } = Select;

// const QRCodeGenerator = () => {
//   const [text, setText] = useState("");
//   const [label, setLabel] = useState("");
//   const [file, setFile] = useState(null);
//   const [category, setCategory] = useState("");
//   const [qrCodeURL, setQRCodeURL] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [Type, setType] = useState("permanent");
//   const [duration, setDuration] = useState(10);
//   const [isSecure, setIsSecure] = useState(false);
//   const [pin, setPin] = useState("");

//   const token = localStorage.getItem("token");

//   useEffect(() => {
//     const fetchQr = async () => {
//       try {
//         const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         console.log({ response });
//         setQRCodeURL(response.data._id);
//       } catch (error) {
//         console.error("Login error:", error.message);
//       }
//     };
//     fetchQr();
//   }, [token]);

//   const handleGenerate = async () => {
//     setLoading(true);
//     try {
//       const formData = new FormData();
//       formData.append("file", file);

//       const data = {
//         text: text,
//         qrCodeId: qrCodeURL,
//         label: label,
//         category: category,
//         isTemporary: Type === "temporary",
//         contentDuration: Type === "temporary" ? Number(duration) : null,
//         isSecure,
//         pin: isSecure ? pin : null,
//       };

//       const response = await axios.post(`${BaseUrl}/api/content/content`, formData, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "multipart/form-data",
//         },
//         params: data,
//       });

//       toast.success(response.data.message, {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//       });
//       console.log({ response });
//     } catch (error) {
//       console.error("Error creating content", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="qr-code-generator-container">
//       <Card
//         title="Upload Content"
//         style={{
//           width: "100%",
//           maxWidth: "600px",
//           margin: "0 auto",
//           borderRadius: "12px",
//           boxShadow: "0 4px 8px rgba(211, 211, 211, 0.1)",
//           color: "#f3b562",
//           backgroundColor: "rgba(211, 211, 211, 0.29)", // Light gray background
//         }}

//       >
//         <Input
//           placeholder="Enter Text"
//           value={text}
//           onChange={(e) => setText(e.target.value)}
//           disabled={file !== null}
//           style={{ marginBottom: "16px", color: "#f3b562" }}
//         />

//         <p style={{ textAlign: "center", color: "#F3B562", margin: "16px 0" }}>OR</p>

//         <Select
//           placeholder="Select a Category"
//           style={{ width: "100%", marginBottom: "16px" }}
//           onChange={(value) => setCategory(value)}
//           value={category}
//         >
//           <Option value="Personal">Personal</Option>
//           <Option value="Family">Family</Option>
//           <Option value="Party">Party</Option>
//           <Option value="Work">Work</Option>
//           <Option value="Other">Other</Option>
//         </Select>

//         <Input
//           placeholder="Enter Label"
//           value={label}
//           onChange={(e) => setLabel(e.target.value)}
//           style={{ marginBottom: "16px", color: "#f3b562" }}
//         />

//         <label htmlFor="file-upload" className="custom-file-upload-content">
//           Choose File
//           <input
//             id="file-upload"
//             type="file"
//             onChange={(e) => setFile(e.target.files[0])}
//             disabled={text !== ""}
//             style={{ display: "none" }}
//           />
//         </label>

//         {file && <p className="file-name">{file.name}</p>}

//         <div style={{ margin: "16px 0" }}>
//           <Radio.Group value={Type} onChange={(e) => setType(e.target.value)}>
//             <Radio value="permanent">Permanent</Radio>
//             <Radio value="temporary">Temporary</Radio>
//           </Radio.Group>
//         </div>

//         {Type === "temporary" && (
//           <Input
//             type="number"
//             placeholder="Duration (minutes)"
//             value={duration}
//             onChange={(e) => setDuration(e.target.value)}
//             min="10"
//             max="1440"
//             style={{ marginBottom: "16px" }}
//           />
//         )}

//         <div style={{ marginBottom: "16px" }}>
//           <Checkbox checked={isSecure} onChange={() => setIsSecure(!isSecure)}>
//             Secure Content
//           </Checkbox>
//         </div>

//         {isSecure && (
//           <Input
//             type="password"
//             placeholder="Enter PIN"
//             value={pin}
//             onChange={(e) => setPin(e.target.value)}
//             style={{ marginBottom: "16px" }}
//           />
//         )}

//         <Button
//           type="primary"
//           onClick={handleGenerate}
//           style={{ width: "100%", backgroundColor: "#F3B562", border: "none" }}
//           loading={loading}
//         >
//           {loading ? "Uploading..." : "Upload"}
//         </Button>
//       </Card>
//     </div>
//   );
// };

// export default QRCodeGenerator;

import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import axios from "axios";
import { BaseUrl } from "../shared/BaseUrl";
import { toast } from "react-toastify";
import { Spin, Select, Card, Input, Radio, Checkbox, Button } from "antd";
import "./Content.css";

const { Option } = Select;

const QRCodeGenerator = () => {
  const [text, setText] = useState("");
  const [label, setLabel] = useState("");
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState("");
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [Type, setType] = useState("permanent");
  const [duration, setDuration] = useState(10);
  const [isSecure, setIsSecure] = useState(false);
  const [pin, setPin] = useState("");
  const [inputType, setInputType] = useState(null); // State to track user selection

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchQr = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log({ response });
        setQRCodeURL(response.data._id);
      } catch (error) {
        console.error("Login error:", error.message);
      }
    };
    fetchQr();
  }, [token]);

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (file) formData.append("file", file);

      const data = {
        text: text,
        qrCodeId: qrCodeURL,
        label: label,
        category: category,
        isTemporary: Type === "temporary",
        contentDuration: Type === "temporary" ? Number(duration) : null,
        isSecure,
        pin: isSecure ? pin : null,
      };

      const response = await axios.post(`${BaseUrl}/api/content/content`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        params: data,
      });

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log({ response });
    } catch (error) {
      console.error("Error creating content", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="qr-code-generator-container">
      <Card
        title="Upload Content"
        style={{
          width: "100%",
          maxWidth: "600px",
          margin: "0 auto",
          borderRadius: "12px",
          boxShadow: "0 4px 8px rgba(211, 211, 211, 0.1)",
          color: "#f3b562",
          backgroundColor: "rgba(211, 211, 211, 0.29)",
        }}
      >
        {/* Radio Group for Selection */}
        <Radio.Group
          onChange={(e) => setInputType(e.target.value)}
          value={inputType}
          style={{ marginBottom: "16px" }}
        >
          <Radio value="text">Enter Text</Radio>
          <Radio value="content">Enter Content</Radio>
        </Radio.Group>

        {/* Show Text Input if "Enter Text" is selected */}
        {inputType === "text" && (
          <Input
            placeholder="Enter Text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            style={{ marginBottom: "16px", color: "#f3b562" }}
          />
        )}

        {/* Show Content Fields if "Enter Content" is selected */}
        {inputType === "content" && (
          <>
            <Select
              placeholder="Select a Category"
              style={{ width: "100%", marginBottom: "16px" }}
              onChange={(value) => setCategory(value)}
              value={category}
            >
              <Option value="Personal">Personal</Option>
              <Option value="Family">Family</Option>
              <Option value="Party">Party</Option>
              <Option value="Work">Work</Option>
              <Option value="Other">Other</Option>
            </Select>

            <Input
              placeholder="Enter Label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              style={{ marginBottom: "16px", color: "#f3b562" }}
            />

            <label htmlFor="file-upload" className="custom-file-upload-content">
              Choose File
              <input
                id="file-upload"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                style={{ display: "none" }}
              />
            </label>

            {file && <p className="file-name">{file.name}</p>}

            <div style={{ margin: "16px 0" }}>
              <Radio.Group value={Type} onChange={(e) => setType(e.target.value)}>
                <Radio value="permanent">Permanent</Radio>
                <Radio value="temporary">Temporary</Radio>
              </Radio.Group>
            </div>

            {Type === "temporary" && (
              <Input
                type="number"
                placeholder="Duration (minutes)"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                min="10"
                max="1440"
                style={{ marginBottom: "16px" }}
              />
            )}

            <div style={{ marginBottom: "16px" }}>
              <Checkbox checked={isSecure} onChange={() => setIsSecure(!isSecure)}>
                Secure Content
              </Checkbox>
            </div>

            {isSecure && (
              <Input
                type="password"
                placeholder="Enter PIN"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                style={{ marginBottom: "16px" }}
              />
            )}
          </>
        )}

        {/* Upload Button (Enabled only if inputType is selected and required fields are filled) */}
        <Button
          type="primary"
          onClick={handleGenerate}
          style={{ width: "100%", backgroundColor: "#F3B562", border: "none" }}
          loading={loading}
          disabled={
            !inputType || // Disable if no input type is selected
            (inputType === "text" && !text) || // Disable if "Enter Text" is selected but no text is entered
            (inputType === "content" && !file) // Disable if "Enter Content" is selected but no file is selected
          }
        >
          {loading ? "Uploading..." : "Upload"}
        </Button>
      </Card>
    </div>
  );
};

export default QRCodeGenerator;