

// import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// import {
//   Table,
//   Layout,
//   Spin,
//   Checkbox,
//   Modal,
//   Space,
//   Button,
//   Select,
//   Card,
//   Row,
//   Col,
//   Typography,
//   Image,
// } from "antd";
// import axios from "axios";
// import { BaseUrl } from "../shared/BaseUrl";
// import QRCode from "qrcode.react";
// import { toast } from "react-toastify";
// import { DeleteTwoTone, RemoveRedEyeTwoTone } from "@mui/icons-material";

// const { Content } = Layout;
// const { Option } = Select;
// const { Title, Text } = Typography;

// export const Dashboard = () => {
//   const [qrCodeURL, setQRCodeURL] = useState("");
//   const [qrId, setQrId] = useState();
//   const [contents, setContents] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentContentId, setCurrentContentId] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//   const [contentToDelete, setContentToDelete] = useState(null);
//   const [selectedType, setSelectedType] = useState("audio");
//   const [selectedCategory, setSelectedCategory] = useState("Personal");
//   const [previewContent, setPreviewContent] = useState(null); // State for preview content
//   const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false); // State for preview modal
//   const token = localStorage.getItem("token");

//   const qrCodeRef = useRef();

//   useLayoutEffect(() => {
//     const fetchQr = async () => {
//       try {
//         const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         toast.success(response.data.message, {
//           position: "top-right",
//           autoClose: 3000,
//         });
//         setQRCodeURL(response.data.text);
//         setQrId(response.data._id);
//       } catch (error) {
//         console.error("Login error:", error.message);
//       }
//     };
//     fetchQr();
//   }, [token]);

//   useEffect(() => {
//     if (qrId) {
//       const fetchContents = async () => {
//         try {
//           const response = await axios.get(
//             `${BaseUrl}/api/content/contents/${qrId}`,
//             {
//               headers: { Authorization: `Bearer ${token}` },
//             }
//           );
//           setContents(response?.data?.contents);
//           setCurrentContentId(response?.data?.currentContent?._id);
//           setLoading(false);
//         } catch (error) {
//           console.error("Fetch content error:", error.message);
//           setLoading(false);
//         }
//       };
//       fetchContents();
//     }
//   }, [qrId, token]);

//   const handleContentTypeChange = (value) => {
//     setSelectedType(value);
//   };

//   const handleCheckboxChange = async (checked, record) => {
//     if (checked) {
//       setIsModalOpen(true);
//       setCurrentContentId(record._id);
//     } else {
//       setCurrentContentId(null);
//     }
//   };

//   const handleOk = async () => {
//     const response = await axios.post(
//       `${BaseUrl}/api/content/set/${currentContentId}`,
//       { qrId: qrId },
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     toast.success(response.data.message, {
//       position: "top-right",
//       autoClose: 3000,
//     });
//     setIsModalOpen(false);
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const handleDelete = async (contentId) => {
//     try {
//       const response = await axios.delete(
//         `${BaseUrl}/api/content/delete/${contentId}`,
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       toast.success(response.data.message, {
//         position: "top-right",
//         autoClose: 3000,
//       });
//       setContents(contents.filter((item) => item._id !== contentId));
//     } catch (error) {
//       console.error("Delete content error:", error.message);
//     }
//   };

//   const handleDeleteOk = () => {
//     if (contentToDelete) {
//       handleDelete(contentToDelete);
//       setIsDeleteModalOpen(false);
//     }
//   };

//   const handleDeleteCancel = () => {
//     setIsDeleteModalOpen(false);
//   };

//   // Function to handle preview
//   const handlePreview = (record) => {
//     setPreviewContent(record);
//     setIsPreviewModalOpen(true);
//   };

//   // Function to close the preview modal
//   const handlePreviewCancel = () => {
//     setIsPreviewModalOpen(false);
//   };

//   const columns = [
//     {
//       title: "ID",
//       key: "index",
//       render: (text, record, index) => index + 1,
//     },
//     {
//       title: "Text",
//       dataIndex: "text",
//       key: "text",
//       render: (text) => (text ? text : <b>N/A</b>),
//     },
//     {
//       title: "Label",
//       dataIndex: "label",
//       key: "label",
//       render: (label) => (label ? <b> {label}</b> : <b>N/A</b>),
//     },
//     {
//       title: "Upload Time",
//       dataIndex: "createdAt",
//       key: "createdAt",
//     },
//     {
//       title: "View",
//       key: "action",
//       render: (text, record) => (
//         <Space size="middle">
//           <Button type="link" onClick={() => handlePreview(record)}>
//             <RemoveRedEyeTwoTone/>
//           </Button>
//         </Space>
//       ),
//     },
//     {
//       title: "Delete",
//       key: "delete",
//       render: (text, record) => (
//         <Space size="middle">
//           <DeleteTwoTone
//             style={{ color: "#ff4d4f", cursor: "pointer" }}
//             onClick={() => {
//               setIsDeleteModalOpen(true);
//               setContentToDelete(record._id);
//             }}
//           />
//         </Space>
//       ),
//     },
//     {
//       title: "Select",
//       key: "checkbox",
//       render: (text, record) => (
//         <Checkbox
//           checked={record._id === currentContentId}
//           onChange={(e) => handleCheckboxChange(e.target.checked, record)}
//         />
//       ),
//     },
//   ];

//   const contentMapping = {
//     audio: contents.filter(
//       (content) =>
//         content.contentType === "audio" && content.category === selectedCategory
//     ),
//     video: contents.filter(
//       (content) =>
//         content.contentType === "video" && content.category === selectedCategory
//     ),
//     image: contents.filter(
//       (content) =>
//         content.contentType === "image" && content.category === selectedCategory
//     ),
//     text: contents.filter(
//       (content) =>
//         content.contentType === "text" && content.category === selectedCategory
//     ),
//   };

//   const handleDownloadQRCode = () => {
//     const canvas = qrCodeRef.current.querySelector("canvas");
//     const pngUrl = canvas
//       .toDataURL("image/png")
//       .replace("image/png", "image/octet-stream");
//     let downloadLink = document.createElement("a");
//     downloadLink.href = pngUrl;
//     downloadLink.download = "qr_code.png";
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
//     document.body.removeChild(downloadLink);
//   };

//   return (
//     <Layout style={{ padding: "24px", background: "transparent" }}>
//       {loading ? (
//         <Spin
//           tip="Loading..."
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "100vh",
//           }}
//         />
//       ) : (
//         <>
//           <Card
//             style={{
//               marginBottom: "24px",
//               borderRadius: "8px",
//               backgroundColor: "#f3b562",
//             }}
//             bodyStyle={{ padding: "24px" }}
//           >
//             <Title level={3}>Dashboard</Title>
//             <Text type="secondary">
//               Manage your QR code and content here.
//             </Text>
//           </Card>

//           <Row gutter={[24, 24]}>
//             <Col xs={24} md={12}>
//               <Card
//                 title="QR Code"
//                 style={{ borderRadius: "8px", backgroundColor: "#f3b562" }}
//                 bodyStyle={{ textAlign: "center" }}
//               >
//                 <div ref={qrCodeRef}>
//                   <QRCode value={qrCodeURL} size={200} />
//                 </div>
//                 <Button
//                   type="primary"
//                   style={{ marginTop: "16px" }}
//                   onClick={handleDownloadQRCode}
//                 >
//                   Download QR Code
//                 </Button>
//               </Card>
//             </Col>

//             <Col xs={24} md={12}>
//               <Card
//                 title="Filters"
//                 style={{ borderRadius: "8px", backgroundColor: "#f3b562" }}
//                 bodyStyle={{ padding: "24px" }}
//               >
//                 <Select
//                   defaultValue="audio"
//                   style={{ width: "100%", marginBottom: "16px" }}
//                   onChange={handleContentTypeChange}
//                 >
//                   <Option value="audio">Audio</Option>
//                   <Option value="video">Video</Option>
//                   <Option value="image">Image</Option>
//                   <Option value="text">Text</Option>
//                 </Select>

//                 <Select
//                   defaultValue="Personal"
//                   style={{ width: "100%" }}
//                   onChange={(value) => setSelectedCategory(value)}
//                 >
//                   <Option value="Personal">Personal</Option>
//                   <Option value="Family">Family</Option>
//                   <Option value="Party">Party</Option>
//                   <Option value="Work">Work</Option>
//                   <Option value="Other">Other</Option>
//                 </Select>
//               </Card>
//             </Col>
//           </Row>

//           <Card
//             style={{
//               marginTop: "24px",
//               borderRadius: "8px",
//               backgroundColor: "#f3b562",
//             }}
//             bodyStyle={{ padding: "24px" }}
//           >
//             <Table
//               columns={columns}
//               dataSource={contentMapping[selectedType]}
//               rowKey={(record) => record._id}
//               pagination={{ pageSize: 5 }}
//               scroll={{ x: true }} // Enable horizontal scrolling
//             />
//           </Card>

//           {/* Preview Modal */}
//           <Modal
//             title="Content Preview"
//             open={isPreviewModalOpen}
//             onCancel={handlePreviewCancel}
//             footer={null}
//             width={800}
//           >
//             {previewContent && (
//               <div>
//                 {previewContent.contentType === "image" && (
//                   <Image
//                     src={previewContent.contentUrl}
//                     alt="Preview"
//                     style={{ width: "100%" }}
//                   />
//                 )}
//                 {previewContent.contentType === "video" && (
//                   <video controls style={{ width: "100%" }}>
//                     <source src={previewContent.contentUrl} type="video/mp4" />
//                     Your browser does not support the video tag.
//                   </video>
//                 )}
//                 {previewContent.contentType === "audio" && (
//                   <audio controls style={{ width: "100%" }}>
//                     <source src={previewContent.contentUrl} type="audio/mpeg" />
//                     Your browser does not support the audio tag.
//                   </audio>
//                 )}
//                 {previewContent.contentType === "text" && (
//                   <p>{previewContent.text}</p>
//                 )}
//               </div>
//             )}
//           </Modal>

//           {/* Confirm Selection Modal */}
//           <Modal
//             title="Confirm Selection"
//             open={isModalOpen}
//             onOk={handleOk}
//             onCancel={handleCancel}
//             okText="Set Content"
//           >
//             <p>
//               Are you sure you want to select this content as the default for
//               your QR code?
//             </p>
//           </Modal>

//           {/* Confirm Deletion Modal */}
//           <Modal
//             title="Confirm Deletion"
//             open={isDeleteModalOpen}
//             onOk={handleDeleteOk}
//             onCancel={handleDeleteCancel}
//             okText="Delete"
//           >
//             <p>Are you sure you want to delete this content?</p>
//           </Modal>
//         </>
//       )}
//     </Layout>
//   );
// };


import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Table,
  Layout,
  Spin,
  Checkbox,
  Modal,
  Space,
  Button,
  Select,
  Card,
  Row,
  Col,
  Typography,
  Image,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../shared/BaseUrl";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import { DeleteTwoTone, RemoveRedEyeTwoTone } from "@mui/icons-material";

const { Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

export const Dashboard = () => {
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [qrId, setQrId] = useState();
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentContentId, setCurrentContentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [selectedType, setSelectedType] = useState("audio");
  const [selectedCategory, setSelectedCategory] = useState("Personal");
  const [previewContent, setPreviewContent] = useState(null); // State for preview content
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false); // State for preview modal
  const token = localStorage.getItem("token");

  const qrCodeRef = useRef();

  useLayoutEffect(() => {
    const fetchQr = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setQRCodeURL(response.data.text);
        setQrId(response.data._id);
      } catch (error) {
        console.error("Login error:", error.message);
      }
    };
    fetchQr();
  }, [token]);

  useEffect(() => {
    if (qrId) {
      const fetchContents = async () => {
        try {
          const response = await axios.get(
            `${BaseUrl}/api/content/contents/${qrId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setContents(response?.data?.contents);
          setCurrentContentId(response?.data?.currentContent?._id);
          setLoading(false);
        } catch (error) {
          console.error("Fetch content error:", error.message);
          setLoading(false);
        }
      };
      fetchContents();
    }
  }, [qrId, token]);

  const handleContentTypeChange = (value) => {
    setSelectedType(value);
  };

  const handleCheckboxChange = async (checked, record) => {
    if (checked) {
      setIsModalOpen(true);
      setCurrentContentId(record._id);
    } else {
      setCurrentContentId(null);
    }
  };

  const handleOk = async () => {
    const response = await axios.post(
      `${BaseUrl}/api/content/set/${currentContentId}`,
      { qrId: qrId },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    toast.success(response.data.message, {
      position: "top-right",
      autoClose: 3000,
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (contentId) => {
    try {
      const response = await axios.delete(
        `${BaseUrl}/api/content/delete/${contentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
      });
      setContents(contents.filter((item) => item._id !== contentId));
    } catch (error) {
      console.error("Delete content error:", error.message);
    }
  };

  const handleDeleteOk = () => {
    if (contentToDelete) {
      handleDelete(contentToDelete);
      setIsDeleteModalOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  // Function to handle preview
  const handlePreview = (record) => {
    setPreviewContent(record);
    setIsPreviewModalOpen(true);
  };

  // Function to close the preview modal
  const handlePreviewCancel = () => {
    setIsPreviewModalOpen(false);
  };

  // Custom footer for the Confirm Selection Modal
  const confirmSelectionFooter = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        key="ok"
        type="primary"
        onClick={handleOk}
        style={{ marginLeft: "8px" }}
      >
        Set Content
      </Button>
    </div>
  );

  const columns = [
    {
      title: "ID",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      render: (text) => (text ? text : <b>N/A</b>),
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      render: (label) => (label ? <b> {label}</b> : <b>N/A</b>),
    },
    {
      title: "Upload Time",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "View",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handlePreview(record)}>
            <RemoveRedEyeTwoTone />
          </Button>
        </Space>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => (
        <Space size="middle">
          <DeleteTwoTone
            style={{ color: "#ff4d4f", cursor: "pointer" }}
            onClick={() => {
              setIsDeleteModalOpen(true);
              setContentToDelete(record._id);
            }}
          />
        </Space>
      ),
    },
    {
      title: "Select",
      key: "checkbox",
      render: (text, record) => (
        <Checkbox
          checked={record._id === currentContentId}
          onChange={(e) => handleCheckboxChange(e.target.checked, record)}
        />
      ),
    },
  ];

  const contentMapping = {
    audio: contents.filter(
      (content) =>
        content.contentType === "audio" && content.category === selectedCategory
    ),
    video: contents.filter(
      (content) =>
        content.contentType === "video" && content.category === selectedCategory
    ),
    image: contents.filter(
      (content) =>
        content.contentType === "image" && content.category === selectedCategory
    ),
    text: contents.filter(
      (content) =>
        content.contentType === "text" && content.category === selectedCategory
    ),
  };

  const handleDownloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr_code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Layout style={{ padding: "24px", background: "transparent" }}>
      {loading ? (
        <Spin
          tip="Loading..."
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      ) : (
        <>
          <Card
            style={{
              marginBottom: "24px",
              borderRadius: "8px",
              backgroundColor: "#f3b562",
            }}
            bodyStyle={{ padding: "24px" }}
          >
            <Title level={3}>Dashboard</Title>
            <Text type="secondary">
              Manage your QR code and content here.
            </Text>
          </Card>

          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Card
                title="QR Code"
                style={{ borderRadius: "8px", backgroundColor: "#f3b562" }}
                bodyStyle={{ textAlign: "center" }}
              >
                <div ref={qrCodeRef}>
                  <QRCode value={qrCodeURL} size={200} />
                </div>
                <Button
                  type="primary"
                  style={{ marginTop: "16px" }}
                  onClick={handleDownloadQRCode}
                >
                  Download QR Code
                </Button>
              </Card>
            </Col>

            <Col xs={24} md={12}>
              <Card
                title="Filters"
                style={{ borderRadius: "8px", backgroundColor: "#f3b562" }}
                bodyStyle={{ padding: "24px" }}
              >
                <Select
                  defaultValue="audio"
                  style={{ width: "100%", marginBottom: "16px" }}
                  onChange={handleContentTypeChange}
                >
                  <Option value="audio">Audio</Option>
                  <Option value="video">Video</Option>
                  <Option value="image">Image</Option>
                  <Option value="text">Text</Option>
                </Select>

                <Select
                  defaultValue="Personal"
                  style={{ width: "100%" }}
                  onChange={(value) => setSelectedCategory(value)}
                >
                  <Option value="Personal">Personal</Option>
                  <Option value="Family">Family</Option>
                  <Option value="Party">Party</Option>
                  <Option value="Work">Work</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Card>
            </Col>
          </Row>

          <Card
            style={{
              marginTop: "24px",
              borderRadius: "8px",
              backgroundColor: "#f3b562",
            }}
            bodyStyle={{ padding: "24px" }}
          >
            <Table
              columns={columns}
              dataSource={contentMapping[selectedType]}
              rowKey={(record) => record._id}
              pagination={{ pageSize: 5 }}
              scroll={{ x: true }} // Enable horizontal scrolling
            />
          </Card>

          {/* Preview Modal */}
          <Modal
            title="Content Preview"
            open={isPreviewModalOpen}
            onCancel={handlePreviewCancel}
            footer={null}
            width={800}
          >
            {previewContent && (
              <div>
                {previewContent.contentType === "image" && (
                  <Image
                    src={previewContent.contentUrl}
                    alt="Preview"
                    style={{ width: "100%" }}
                  />
                )}
                {previewContent.contentType === "video" && (
                  <video controls style={{ width: "100%" }}>
                    <source src={previewContent.contentUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                {previewContent.contentType === "audio" && (
                  <audio controls style={{ width: "100%" }}>
                    <source src={previewContent.contentUrl} type="audio/mpeg" />
                    Your browser does not support the audio tag.
                  </audio>
                )}
                {previewContent.contentType === "text" && (
                  <p>{previewContent.text}</p>
                )}
              </div>
            )}
          </Modal>

          {/* Confirm Selection Modal */}
          <Modal
            title="Confirm Selection"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={confirmSelectionFooter} // Use custom footer
          >
            <p>
              Are you sure you want to select this content as the default for
              your QR code?
            </p>
          </Modal>

          {/* Confirm Deletion Modal */}
          <Modal
            title="Confirm Deletion"
            open={isDeleteModalOpen}
            onOk={handleDeleteOk}
            onCancel={handleDeleteCancel}
            okText="Delete"
          >
            <p>Are you sure you want to delete this content?</p>
          </Modal>
        </>
      )}
    </Layout>
  );
};