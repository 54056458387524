
// import React, { useEffect, useLayoutEffect, useState } from "react";
// import { Layout, Spin, Modal, Input } from "antd";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import { BaseUrl } from "../shared/BaseUrl";
// import AudioPlayer from "react-h5-audio-player";
// import "react-h5-audio-player/lib/styles.css";
// import ReactPlayer from "react-player";
// import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import "./currentContent.css";

// const { Content } = Layout;

// export const CurrentContent = () => {
//   const [qrCodeURL, setQRCodeURL] = useState("");
//   const [currentContent, setCurrentContent] = useState("");
//   const [qrId, setQrId] = useState();
//   const token = localStorage.getItem("token");
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const userId = searchParams.get("un");
//   const [pin, setPin] = useState(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [tempPin, setTempPin] = useState("");

//   useLayoutEffect(() => {
//     const fetchQr = async () => {
//       try {
//         const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         if (response.data.data === null) {
//           // Check if data is null
//           // navigate("/content"); // Navigate to the desired route
//         } else {
//           setQRCodeURL(response.data.text);
//           setQrId(response?.data?._id);
//         }
//         console.log(response.data);
//       } catch (error) {
//         if (error.response && error.response.status === 400) {
//           if (error.response.data.data === null) {
//             toast.error("no content found yet please generate one", {
//               position: "top-right",
//               autoClose: 3000,
//               hideProgressBar: false,
//               closeOnClick: true,
//               pauseOnHover: true,
//               draggable: true,
//               progress: undefined,
//             });
//           } else {
//             console.error("Login error:", error.response.data.message);
//           }
//         } else {
//           console.error("Login error:", error.message);
//         }
//       }
//     };
//     fetchQr();
//   }, [token]);

//   const fetchCurrentQr = async (pin) => {
//     try {
//       const endpoint = userId ? `user/${userId}` : `qr/${qrId}`;

//       const headers = { Authorization: `Bearer ${token}` };
//       if (pin) {
//         headers["x-content-pin"] = pin;
//       }

//       const response = await axios.get(
//         `${BaseUrl}/api/content/current-content/${endpoint}`,
//         { headers }
//       );

//       setCurrentContent(response?.data.currentContent);
//     } catch (error) {
//       if (error.response && error.response.data.message === "Invalid PIN provided") {
//         setIsModalVisible(true);
//       } else {
//         console.error("Error fetching current content:", error.message);
//       }
//     }
//   };

//   useEffect(() => {
//     fetchCurrentQr(pin);
//   }, [userId, qrId, token, pin]);

//   const handleOk = () => {
//     setPin(tempPin);
//     setIsModalVisible(false);
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   return (
//     <Layout
//       style={{
//         padding: "0 24px 24px",
//         height: "95vh",
//         background: "transparent",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       <div>
//         {currentContent?.contentType === "image" && (
//           <img
//             style={{
//               alignItems: "center",
//               width: "100%",
//               height: "100%",
//               alignContent: "center",
//             }}
//             src={currentContent.contentUrl}
//             alt="current image"
//           />
//         )}
//         {currentContent?.contentType === "audio" && (
//           <AudioPlayer
//             autoPlay
//             src={currentContent.contentUrl}
//             onPlay={(e) => console.log("onPlay")}
//             className="react-player"
//           />
//         )}
//         {currentContent?.contentType === "video" && (
//           <ReactPlayer
//             playing={true}
//             url={currentContent.contentUrl}
//             height={"70vh"}
//             width={"80vw"}
//             controls={true}
//           />
//         )}
//         {currentContent?.contentType === "text" && (
//           <p style={{ color: "rgb(243, 181, 98)" }}>{currentContent?.text}</p>
//         )}
//         {!currentContent && (
//           <Spin
//             tip="Loading..."
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "100vh",
//             }}
//           />
//         )}
//       </div>

//       <Modal
//         title="Enter PIN"
//         visible={isModalVisible}
//         onOk={handleOk}
//         onCancel={handleCancel}
//       >
//         <Input
//           placeholder="Enter PIN"
//           value={tempPin}
//           onChange={(e) => setTempPin(e.target.value)}
//         />
//       </Modal>
//     </Layout>
//   );
// };


import React, { useEffect, useLayoutEffect, useState } from "react";
import { Layout, Spin, Modal, Input, Button } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import { BaseUrl } from "../shared/BaseUrl";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "./currentContent.css";

const { Content } = Layout;

export const CurrentContent = () => {
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [currentContent, setCurrentContent] = useState("");
  const [qrId, setQrId] = useState();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("un");
  const [pin, setPin] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempPin, setTempPin] = useState("");

  useLayoutEffect(() => {
    const fetchQr = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.data === null) {
          // Check if data is null
          // navigate("/content"); // Navigate to the desired route
        } else {
          setQRCodeURL(response.data.text);
          setQrId(response?.data?._id);
        }
        console.log(response.data);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data.data === null) {
            toast.error("no content found yet please generate one", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Login error:", error.response.data.message);
          }
        } else {
          console.error("Login error:", error.message);
        }
      }
    };
    fetchQr();
  }, [token]);

  const fetchCurrentQr = async (pin) => {
    try {
      const endpoint = userId ? `user/${userId}` : `qr/${qrId}`;

      const headers = { Authorization: `Bearer ${token}` };
      if (pin) {
        headers["x-content-pin"] = pin;
      }

      const response = await axios.get(
        `${BaseUrl}/api/content/current-content/${endpoint}`,
        { headers }
      );

      setCurrentContent(response?.data.currentContent);
    } catch (error) {
      if (error.response && error.response.data.message === "Invalid PIN provided") {
        setIsModalVisible(true);
      } else {
        console.error("Error fetching current content:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchCurrentQr(pin);
  }, [userId, qrId, token, pin]);

  const handleOk = () => {
    setPin(tempPin);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Custom footer for the Modal
  const modalFooter = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        key="ok"
        type="primary"
        onClick={handleOk}
        style={{ marginLeft: "8px" }}
      >
        OK
      </Button>
    </div>
  );

  return (
    <Layout
      style={{
        padding: "0 24px 24px",
        height: "95vh",
        background: "transparent",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        {currentContent?.contentType === "image" && (
          <img
            style={{
              alignItems: "center",
              width: "100%",
              height: "100%",
              alignContent: "center",
            }}
            src={currentContent.contentUrl}
            alt="current image"
          />
        )}
        {currentContent?.contentType === "audio" && (
          <AudioPlayer
            autoPlay
            src={currentContent.contentUrl}
            onPlay={(e) => console.log("onPlay")}
            className="react-player"
          />
        )}
        {currentContent?.contentType === "video" && (
          <ReactPlayer
            playing={true}
            url={currentContent.contentUrl}
            height={"70vh"}
            width={"80vw"}
            controls={true}
          />
        )}
        {currentContent?.contentType === "text" && (
          <p style={{ color: "rgb(243, 181, 98)" }}>{currentContent?.text}</p>
        )}
        {!currentContent && (
          <Spin
            tip="Loading..."
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          />
        )}
      </div>

      <Modal
        title="Enter PIN"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={modalFooter} // Use custom footer
      >
        <Input
          placeholder="Enter PIN"
          value={tempPin}
          onChange={(e) => setTempPin(e.target.value)}
        />
      </Modal>
    </Layout>
  );
};